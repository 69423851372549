// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/cjs.js!../../node_modules/.pnpm/sass-loader@12.6.0_sass@1.77.8_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/sass-loader/dist/cjs.js!./global.scss");
if(content.__esModule) content = content.default;
if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) module.exports = content.locals;
// add the styles to the DOM
var add = require("!../../node_modules/.pnpm/vue-style-loader@4.1.3/node_modules/vue-style-loader/lib/addStylesClient.js").default
var update = add("95b3beb8", content, false, {});
// Hot Module Replacement
if(module.hot) {
 // When the styles change, update the <style> tags
 if(!content.locals) {
   module.hot.accept("!!../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/cjs.js!../../node_modules/.pnpm/sass-loader@12.6.0_sass@1.77.8_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/sass-loader/dist/cjs.js!./global.scss", function() {
     var newContent = require("!!../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/cjs.js!../../node_modules/.pnpm/sass-loader@12.6.0_sass@1.77.8_webpack@5.94.0_webpack-cli@4.10.0_/node_modules/sass-loader/dist/cjs.js!./global.scss");
     if(newContent.__esModule) newContent = newContent.default;
     if(typeof newContent === 'string') newContent = [[module.id, newContent, '']];
     update(newContent);
   });
 }
 // When the module is disposed, remove the <style> tags
 module.hot.dispose(function() { update(); });
}