import Vue from 'vue'
import mdiVue from 'mdi-vue'
import * as mdijs from '@mdi/js'
import Home from './views/Home.vue'
import './global.scss'

Vue.use(mdiVue, {
  icons: mdijs,
})
new Vue({
  components: {
    Home,
  },
  el: '#console-app',
})
