import * as spa from 'single-spa'
import config from './../../config.json'

export function setup() {
  config.apps.forEach((app) => {
    spa.registerApplication({
      name: app.name,
      app: () => System.import(app.name),
      activeWhen: [app.path],
      customProps: Object.assign({}, app.customProps, {
        domElement: document.getElementById(config.appMountElement),
      }),
    })
  })
}

export function start() {
  spa.start({
    urlRerouteOnly: true,
  })
}

export function getActiveApp() {
  return config.apps.find((app) => location.pathname.startsWith(app.path))
}

window.spa = spa
