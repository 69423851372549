var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-app" }, [
    _vm.loggedIn
      ? _c("div", { staticClass: "menu-header" }, [
          _c("div", { staticClass: "d-flex align-center logo-header" }, [
            _c("img", {
              staticClass: "gc-logo-header",
              attrs: {
                src: "https://assets.gamersclub.com.br/header/img/gamers_club_logo_small.8a9ff3c5.svg",
                alt: "logogc",
              },
            }),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "title-header-nav" }, [
              _c(
                "p",
                {
                  staticClass: "title-app",
                  on: {
                    click: function ($event) {
                      _vm.openOptions = !_vm.openOptions
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.currentApp.label || "Selecione") +
                      " "
                  ),
                  _c("mdicon", { attrs: { name: "MenuDown" } }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.openOptions
                ? _c(
                    "div",
                    { staticClass: "list-apps-wrapper" },
                    _vm._l(_vm.categorizedApps, function (item) {
                      return _c(
                        "ul",
                        { key: item.category, staticClass: "list-apps" },
                        _vm._l(item.apps, function (app) {
                          return _c(
                            "li",
                            {
                              key: app.name,
                              on: {
                                click: function ($event) {
                                  return _vm.activateApp(app)
                                },
                              },
                            },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  [_c("mdicon", { attrs: { name: app.icon } })],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "label-app" }, [
                                  _vm._v(_vm._s(app.label)),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile" }, [
            _c(
              "div",
              {
                staticClass: "profile-icon",
                on: {
                  click: function ($event) {
                    _vm.openMenu = !_vm.openMenu
                  },
                },
              },
              [_c("mdicon", { attrs: { name: "AccountCircle" } })],
              1
            ),
            _vm._v(" "),
            _vm.openMenu
              ? _c("div", { staticClass: "menu-profile" }, [
                  _c("div", [
                    _c("div", [
                      _c("h2", { staticClass: "account-name" }, [
                        _vm._v(_vm._s(_vm.accountName)),
                      ]),
                      _vm._v(" "),
                      _c("h3", [_vm._v(_vm._s(_vm.accountEmail))]),
                      _vm._v(" "),
                      _c("h3", [_vm._v("id: " + _vm._s(_vm.accountId))]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "logout-button",
                      on: {
                        click: function ($event) {
                          return _vm.logout()
                        },
                      },
                    },
                    [_c("span", [_vm._v("Logout")])]
                  ),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "d-flex justify-center align-center" }, [
      _c("span", { staticClass: "title-login" }, [_vm._v("Console")]),
      _vm._v(" "),
      _c("span", { staticClass: "title-login-dot" }, [_vm._v(".")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("main", [_c("div", { attrs: { id: "console-app-mount" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }