<template>
  <div class="page-app">
    <div v-if="loggedIn" class="menu-header">
      <div class="d-flex align-center logo-header">
        <img
          src="https://assets.gamersclub.com.br/header/img/gamers_club_logo_small.8a9ff3c5.svg"
          alt="logogc"
          class="gc-logo-header"
        />
        <h1 class="d-flex justify-center align-center">
          <span class="title-login">Console</span>
          <span class="title-login-dot">.</span>
        </h1>
        <div class="title-header-nav">
          <p class="title-app" @click="openOptions = !openOptions">
            {{ currentApp.label || 'Selecione' }} <mdicon name="MenuDown" />
          </p>
          <div class="list-apps-wrapper" v-if="openOptions">
            <ul
              class="list-apps"
              v-for="item in categorizedApps"
              :key="item.category"
            >
              <li
                v-for="app in item.apps"
                :key="app.name"
                @click="activateApp(app)"
              >
                <div>
                  <span><mdicon :name="app.icon" /></span>
                  <span class="label-app">{{ app.label }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="profile">
        <div class="profile-icon" @click="openMenu = !openMenu">
          <mdicon name="AccountCircle" />
        </div>
        <div class="menu-profile" v-if="openMenu">
          <div>
            <div>
              <h2 class="account-name">{{ accountName }}</h2>
              <h3>{{ accountEmail }}</h3>
              <h3>id: {{ accountId }}</h3>
            </div>
          </div>

          <button class="logout-button" @click="logout()">
            <span>Logout</span>
          </button>
        </div>
      </div>
    </div>

    <main>
      <div id="console-app-mount"></div>
    </main>
  </div>
</template>

<script>
import { navigateToUrl } from 'single-spa'
import { _shared } from './../../../../auth/src/shared/shared'
import config from './../../config.json'
import * as spa from '../services/spa'
import lodash from 'lodash'
const $auth = _shared.$auth

export default {
  data() {
    return {
      currentApp: {},
      openOptions: false,
      openMenu: false,
    }
  },

  computed: {
    items() {
      return config.apps.filter((app) => !app.hidden)
    },

    categorizedApps() {
      const categories = {}

      lodash.forEach(config.apps, (app) => {
        if (app.hidden) return

        app.category = app.category || ''
        categories[app.category] = categories[app.category] || []
        categories[app.category].push(app)
      })

      let result = lodash.map(categories, (rawApps, category) => {
        const apps = lodash.sortBy(rawApps, ['order', 'label'])
        return { category, apps }
      })

      result = lodash.sortBy(result, (item) => item.category)
      return result
    },

    loggedIn() {
      return $auth.loggedIn
    },

    accountName() {
      const user = $auth.user
      return user.name || user.nickname
    },

    accountEmail() {
      return $auth.user.email
    },

    accountId() {
      return $auth.user.id
    },
  },
  mounted() {
    //this.$vuetify.theme.dark = false
    const urlSearchParams = new URLSearchParams(location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    const path = params.redirectTo || null

    if (!this.loggedIn) {
      $auth.logout(true)
      let redirectTo = ''
      if (location.pathname !== '/' && location.pathname !== '/auth') {
        redirectTo = `?redirectTo=${location.pathname}`
      }

      navigateToUrl(`/auth${redirectTo}`)
    } else if (path) {
      navigateToUrl(path)
    }

    spa.start()
    this.currentApp = spa.getActiveApp() || {}
  },

  methods: {
    activateApp(app) {
      this.openOptions = false
      navigateToUrl(app.path)
      this.currentApp = spa.getActiveApp() || {}
    },

    logout() {
      $auth.logout()
    },

    format(s) {
      return lodash.upperCase(s || '')
    },
  },
}
</script>

<style lang="scss" scoped>
.page-app {
  text-align: left;
  width: 100%;
}
#console-app-mount {
  margin-top: 4rem;
}
.menu-header {
  height: 4rem;
  margin-top: 0px;
  transform: translateY(0px);
  left: 0px;
  right: 0px;
  z-index: 1350 !important;
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
  position: fixed;
  top: 0;
  background: var(--gc-primary);
}
.logo-header {
  margin-left: 2rem;
  flex: 1;
}
.gc-logo-header {
  width: 300ppx;
}
.title-login {
  color: #fff;
  margin-left: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
}
.title-login-dot {
  color: #0bc8d5;
  font-weight: bold;
  font-size: 1.5rem;
}
.title-header-nav {
  position: relative;
  font-weight: bold;
  font-size: 1.5rem;
}
.title-app {
  color: #fff;
  margin-left: 0.5rem;
  font-weight: normal;
  font-size: 1.5rem;
  cursor: pointer;
}
.list-apps-wrapper {
  position: absolute;
  top: 100%;
  min-width: 14rem;
  background: var(--gc-ui-1);
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
  border-radius: 0.25rem;
  padding: 0.25rem 0;
  margin-top: 1rem;
}
.list-apps {
  padding: 0.25rem;
  li {
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    div {
      display: flex;
      align-items: center;
    }
    .label-app {
      margin-top: 0.25rem;
      margin-left: 0.5rem;
    }
    &:hover {
      background-color: var(--grey-2);
      border-radius: 0.25rem;
    }
    span {
      font-weight: 700;
      font-size: 1.25rem;
    }
  }
}
.profile {
  margin-right: 2rem;
}
.profile-icon {
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.25rem;
  &:hover {
    background-color: var(--grey-2);
  }
}
.menu-profile {
  position: absolute;
  top: 3.5rem;
  right: 1rem;
  background: var(--gc-ui-1);
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
  padding: 1rem;
  border-radius: 0.25rem;
}
.account-name {
  border-bottom: 1px solid var(--grey-2);
  margin-bottom: 0.5rem;
}
.logout-button {
  margin-top: 0.5rem;
  background: #1976d2;
  color: var(--white);
  margin-top: 0.5rem;
}
</style>
